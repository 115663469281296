import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';      
import 'bootstrap/dist/css/bootstrap.min.css';
import React,{useState, useEffect, useRef} from 'react'; 
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './Shortner.css'
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { submitUrl, all_short_url } from "../../Utils/Urls";

export default function Shortner() {
    const toast = useRef(null);
    const [url, setUrl] = useState('');
    const [locationId, setLocationId] = useState('');
    const [campaign, setCampaing] = useState(null);
    const [campaignId, setCampaingId] = useState(null);
    const [medium, setMedium] = useState(null);
    const [source, setSource] = useState(null);
    const [sourceName, setSourceName] = useState(null);
    const [placement, setPlacement] = useState(null);
    const [adId, setAdId] = useState(null);
    const [adSetId, setAdSetId] = useState(null);
    const [term, setTerm] = useState(null);
    const [content, setContent] = useState(null);
    const [formName, setFormName] = useState(null);
    const [sales, setSales] = useState(null);
    const [affliate, setAffliate] = useState(null);
    const [language, setLanguage] = useState(null);
    const [customEndpoint, setCustomEndpoint] = useState(null);
    const [fullUrl, setFullUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [locationIdError, setLocationIdError] = useState(false);
    const [shortUrlError, setShortUrlError] = useState(false);
    const [allCustomEndpoint, setAllCustomEndpoint] = useState(['']);
    const [isMounted, setIsMounted] = useState(false)
    const [canSubmit, setCanSubmit] = useState(true)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParams = new URLSearchParams();
        const newUrl = urlParams.get('url');
        const newLocationId = urlParams.get('locationId');
    
        if (newUrl) {
            setUrl(newUrl);
            try{
                const generated = new URL(newUrl);
                generated.search = queryParams.toString();
                setFullUrl(generated.toString());
                setError(false); // Clear any previous error
            }
            catch{
                console.log('Invalid URL')
            }
        }
        if (newLocationId) {
            setLocationId(newLocationId);
            if(newUrl){
                queryParams.append('locationId', newLocationId);
                try{
                    const generated = new URL(newUrl);
                    generated.search = queryParams.toString();
                    setFullUrl(generated.toString());
                    setLocationIdError(false)
                }
                catch{
                    console.log('Invalid URL')
                }
            }
        }
      }, [isMounted]);

    useEffect(()=>{
        axios.get(all_short_url, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setAllCustomEndpoint(response.data);
                  }
                })
            .catch((error)=>{
                console.log(error.response.data) 
            })
    },[all_short_url, isMounted])

    const load = async ()=> {
        
        updateGeneratedUrl()
        // Wait for state updates to take effect before checking conditions
        await new Promise((resolve) => {
            setTimeout(resolve, 0);
        });

        if(error || shortUrlError || locationIdError){
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please check if any of the fields are giving an error', life: 3000 });
        }else{
            console.log(error)
            console.log(shortUrlError)
            console.log(locationIdError)
            setLoading(false);
            const data = {
                long_url: fullUrl,
                location_id: locationId,
                campaign: campaign,
                campaignId: campaignId,
                medium: medium,
                source: source,
                sourceName: sourceName,
                placement: placement,
                adId: adId,
                adSetId: adSetId,
                term: term,
                content: content,
                formName: formName,
                sales: sales,
                affliate: affliate,
                language: language,
                short_url: customEndpoint
            }
            if (locationId.length > 0 & fullUrl.length > 0){
                axios.post(submitUrl, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                    .then((response)=>{
                        if(response.status === 201){
                            console.log(response.data)
                            setLoading(false);
                            setIsMounted(!isMounted)
                            toast.current.show({severity:'success', summary: 'Success', detail:'Success', life: 3000});
                        }
                        else{
                            console.log(response.data)
                            setLoading(false);
                            setIsMounted(!isMounted)
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to shorten the Url', life: 3000 });
                        }
                    })
                    .catch((error)=>{
                        setLoading(false);
                        setIsMounted(!isMounted)
                        toast.current.show({severity:'error', summary: 'Error', detail:'Failed to shorten the Url', life: 3000});
                    })
            }
            else{
                console.log(locationId)
                console.log(fullUrl)
                console.log(data)
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please check if any of the fields are giving an error', life: 3000 });
            }
            
        }
    };

    const updateGeneratedUrl = () => {
        try{
            const queryParams = new URLSearchParams();
            if (locationId){
                queryParams.append('locationId', locationId);
                setLocationIdError(false)
                setCanSubmit(true)
            }
            else{
                setLocationIdError(true)
                setCanSubmit(false)
            }
            if (campaign) queryParams.append('utm_campaign', campaign);
            if (campaignId) queryParams.append('utm_Campaign_id', campaignId);
            if (medium) queryParams.append('utm_medium', medium);
            if (source) queryParams.append('utm_source', source);
            if (sourceName) queryParams.append('utm_Site_source_name', sourceName);
            if (placement) queryParams.append('utm_Placement', placement);
            if (adId) queryParams.append('utm_Ad_id', adId);
            if (adSetId) queryParams.append('utm_Adset_id', adSetId);
            if (term) queryParams.append('utm_term', term);
            if (content) queryParams.append('utm_content', content);
            if (formName) queryParams.append('utm_form_name', formName);
            if (sales) queryParams.append('utm_sales', sales);
            if (affliate) queryParams.append('utm_affiliate', affliate);
            if (language) queryParams.append('utm_language', language);

            const generated = new URL(url);
            generated.search = queryParams.toString();
            setFullUrl(generated.toString());
            setError(false); // Clear any previous error
        }
        catch (err){
            setFullUrl('');
            setError(true); // Handle the error
            setCanSubmit(false)
        }
        finally{
            console.log(fullUrl)
            if (customEndpoint){
                if (allCustomEndpoint.includes(customEndpoint)){
                    setShortUrlError(true)
                    setCanSubmit(false)
                }
                else{
                    setShortUrlError(false)
                    setCanSubmit(true)
                }
            }

        }
    };

    const handleCustomEndpointChange = (e) => {
        // Replace spaces with an empty string
        const newValue = e.target.value.replace(/[^\w\d-]/g, '');
        setCustomEndpoint(newValue);
      };

    return (
        <div className="shortner-form-container">
            <Toast ref={toast} />
            <div className="shortner-form">
                <div className="form-header mb-3">
                    <h3>Create Link</h3>
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 border-top">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0 mt-3">URL</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className={`input-text mt-3 ${error ? 'p-invalid' : ''}`} value={url} onChange={(e) => setUrl(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${error ? '' : 'input-text-error-none'}`}>Please enter a valid URL</p>
                        </div>
                    </div>
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0 mt-3">Location Id</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className={`input-text mt-3 ${locationIdError ? 'p-invalid' : ''}`} value={locationId} onChange={(e) => setLocationId(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${locationIdError ? '' : 'input-text-error-none'}`}>Please enter the location id</p>
                        </div>
                    </div>
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">campaign</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={campaign} onChange={(e) => setCampaing(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">campaign id</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={campaignId} onChange={(e) => setCampaingId(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>       
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">medium</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={medium} onChange={(e) => setMedium(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>                 
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">source</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={source} onChange={(e) => setSource(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">source name</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={sourceName} onChange={(e) => setSourceName(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>   
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">placement</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={placement} onChange={(e) => setPlacement(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>              
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">Ad Id</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={adId} onChange={(e) => setAdId(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>        
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">Ad set Id</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={adSetId} onChange={(e) => setAdSetId(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>           
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">term</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={term} onChange={(e) => setTerm(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>                   
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">content</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={content} onChange={(e) => setContent(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>        
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">form name</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={formName} onChange={(e) => setFormName(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>          
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">sales</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={sales} onChange={(e) => setSales(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>                
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">affiliate</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={affliate} onChange={(e) => setAffliate(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>   
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">language</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={language} onChange={(e) => setLanguage(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                    </div>               
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 border-top">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0 mt-3">Custom back-half (optional)</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text mt-3" value={customEndpoint} onChange={handleCustomEndpointChange} onBlur={updateGeneratedUrl}/>
                        </div>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${shortUrlError ? '' : 'input-text-error-none'}`}>This exact link already exists and cannot be duplicated</p>
                        </div>
                    </div>             
                </div>
                <div className="form-footer card d-flex flex-row align-items-center justify-content-center border-0 gap-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-7 col-md-8 col-lg-10" style={{padding:'1rem'}}>
                            <InputText className="full-url-text" value={fullUrl}/>
                        </div>
                        <div className="col-5 col-md-4 col-lg-2" style={{padding:'1rem'}}>
                            <Button className="shorten-button" label="Submit" icon="pi pi-copy" onClick={load} />
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
}